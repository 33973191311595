@import "~bootstrap/scss/functions";
@import "./variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap";

@import "./bootswatch";

// on touch devices
@media (hover: none) {
  .btn-outline-primary:hover {
    color: #158cba;
    background-color: transparent;
  }
}
